<template>
    <menu class="">
        <a href="https://www.instagram.com/_nftworldnews_/" class="action"><img class="imgIcon" src="../assets/icons/instagram-brands.svg"></a>
        <a href="https://discord.gg/GsWphCqHer" class="action"><img class="imgIcon" src="../assets/icons/discord-brands.svg"></a>
        <a href="https://twitter.com/_NFTWorldNews_" class="action"><img class="imgIcon" src="../assets/icons/twitter-brands.svg"></a>
        <a href="https://medium.com/@nftworldnews" class="action"><img class="imgIcon" src="../assets/icons/medium.svg"></a>
        <a href="https://opensea.io/NFT-WORLD-NEWS" class="action"><img class="imgIcon" src="../assets/icons/opensea.svg"></a>
        <a class="trigger"><img class="imgIconMain" src="../assets/icons/wavingHand.svg"></a>
    </menu>
</template>

<script>
export default {
  name: 'FabButton',
  props: [],
  methods: {
    
  },
  mounted() {
    const trigger = document.querySelector("menu > .trigger");
    trigger.addEventListener('click', (e) => {
    e.currentTarget.parentElement.classList.toggle("open");
    });
  }
}
</script>

<style scoped>

.imgIcon{
    height: 20px;
}

.imgIconMain{
    height: 30px;
}

menu {
  --size: 2.5rem;
  --radius: 6rem;
  --padding: 1rem;
  --bg-color: rgb(74, 74, 76);
  --fg-color: rgba(0, 0, 0, 0.7);
  --hi-color: #c22eff;
  
  position: fixed;
  bottom: 86px;
  right: var(--padding);
}

menu > * {
  position: absolute;
  
  display: grid;
  place-content: center;
  
  border-radius: 50%;
  
  background: var(--bg-color);
  color: var(--fg-color);
  
  text-decoration: none;
  
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.6);
}

menu > .action {
  --factor: 0;
  
  width: var(--size);
  height: var(--size);
  right: calc(0.35 * var(--size));
  bottom: 86px;
  
  opacity: 0;
  
  transform: rotate(calc(-1 * var(--angle))) translateY(calc(-1 * var(--radius) * var(--factor))) rotate(var(--angle));
  
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out, box-shadow 250ms ease-in-out, color 250ms ease-in-out;
}

menu.open > .action {
  --factor: 1;
  
  opacity: 1;
}

menu > .action:nth-child(1) {
  --angle: 0deg;
  transition-delay: 0ms;
}

menu > .action:nth-child(2) {
  --angle: 30deg;
  transition-delay: 50ms;
}

menu > .action:nth-child(3) {
  --angle: 60deg;
  transition-delay: 100ms;
}

menu > .action:nth-child(4) {
  --angle: 90deg;
  transition-delay: 150ms;
}

menu > .trigger {
  width: calc(1.6 * var(--size));
  height: calc(1.6 * var(--size));
  bottom: 0;
  right: 0;
  
  font-size: 2rem;
  transition: box-shadow 250ms ease-in-out, color 250ms ease-in-out;
}

menu > .trigger > i {
  transition: transform 250ms ease-in-out;
}

menu.open > .trigger > i {
  transform: rotate(-135deg);
}

</style>
